import React from "react";
import type { WebApp } from "../types/telegram.types";

const TelegramContext = React.createContext<WebApp | undefined>(undefined);

export const TelegramProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [webApp, setWebApp] = React.useState<WebApp>();

  React.useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      setWebApp(app);
    }
  }, []);
  console.log("init data", webApp?.initData);
  console.log("init data unsafe", webApp?.initDataUnsafe);
  console.log("whole data", webApp);

  return (
    <TelegramContext.Provider value={webApp}>
      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => React.useContext(TelegramContext);
